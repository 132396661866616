import React from "react"
import Layout from "../components/Layout"
import Card from "../components/Card"

const Sponsorship = () => {
  const cardWidth = "30%"

  return (
    <Layout>
      <div className="sponsorship__picture" />
      <section className="sponsorship__section">
        <h1>Refugee Sponsorship</h1>
        <div className="sponsorship__description">
          <p>
            Canada is the first country in the world to have a refugee
            sponsorship program that gives churches and other community groups
            the opportunity &lsquo;Privately Sponsored Refugees&rsquo;
            (PSR&apos;s).
          </p>
          <p>
            Your role as a sponsoring church or group is to support the refugee
            (or refugee family) for their first year in Canada. This includes
            support for housing, food, clothing, as well as social and emotional
            support. Learn more about the opportunities, the process, and the
            responsibilities to help you discern if this is a good fit for your
            group. You will find many resources to help you through the
            application process as well as to help support newcomers through the
            process of settlement.
          </p>
        </div>
        <div>
          <Card
            id="resettle_in_canada_as_refugees"
            text="Resettle in Canada as Refugees"
            to="/sponsorship/resettling/"
            width={cardWidth}
          />
          <Card
            id="resources_for_sponsorship_groups"
            text="Resources for Sponsorship Groups"
            to="/sponsorship/resources/"
            width={cardWidth}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Sponsorship
