import { Link } from "gatsby"
import React from "react"

const Card = ({ id, text, to, width }) => {
  return (
    <Link className="card" style={{ width: width }} to={to}>
      <div className={`card__image o__${id}`} />
      <div className="card__text">
        <h3>{text}</h3>
      </div>
    </Link>
  )
}

export default Card
